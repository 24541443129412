$(document).ready(function (){
  const searchIcon = $('.js-search-icon');
  const headerSearchInput = $('.header-search');
  const headerSearchCancel = $('.js-cancel-search');
  const headerSearchCancelMobile = $('.header-right-cancel-mobile--search');
  const headerSearchForm = $('#header-search-form');
  const overlay = $('.overlay-on--search');

  searchIcon.on('click', () => {
    // headerSearchInput.removeClass('header-search--hidden');
    if(window.matchMedia('(min-width: 1024px)').matches) {
      headerSearchInput.toggle('slow');
    } else {
      headerSearchInput.slideDown('280ms');
    }
    searchIcon.hide();
    headerSearchCancelMobile.addClass('cancel-mobile--search-toggle');
    overlay.removeClass('is-hidden');
    $('html').addClass('no-scroll');
  });
  headerSearchCancel.on('click', () => {
    if(window.matchMedia('(min-width: 1024px)').matches) {
      headerSearchInput.toggle('slow');
    } else {
      headerSearchInput.slideUp('280ms');
    }
    headerSearchInput.val('');
    searchIcon.show();
    headerSearchCancelMobile.removeClass('cancel-mobile--search-toggle');
    overlay.addClass('is-hidden');
    $('html').removeClass('no-scroll');
    // it is neccessary to use headerSearchForm[0].reset()
    // as jQuery does not have .reset() method but javascript does.
    headerSearchForm[0].reset();
  });
  $(window).on('resize', () => {
    if(window.matchMedia('(min-width: 1024px)').matches) {
      if(headerSearchInput.css('display') == 'none') {
        headerSearchInput.css('display', 'block');
      }
    }
    if(window.matchMedia('(max-width: 1023px)').matches) {
      if(headerSearchInput.css('display') == 'block') {
        headerSearchInput.css('display', 'none');
        headerSearchInput.val('');
        searchIcon.show();
        headerSearchCancelMobile.removeClass('cancel-mobile--search-toggle');
        overlay.addClass('is-hidden');
        $('html').removeClass('no-scroll');
        headerSearchForm[0].reset();
      }
    }
  });
});
